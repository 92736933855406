import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "テックブログを継続運営するためのメドレーの取り組み",
  "date": "2025-02-26T08:00:00.000Z",
  "slug": "entry/2025/02/26/104016",
  "tags": [],
  "hero": "./thumbnail.png",
  "heroAlt": "テックブログを継続運営するためのメドレーの取り組み"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、`}<a parentName="p" {...{
        "href": "https://us.job-medley.com/"
      }}>{`Jobley`}</a>{` でエンジニアをしている新居です。`}</p>
    <p>{`メドレーでは 2022 年 7 月から現在まで約 2 年半、テックブログをひと月に 1 記事以上のリズムで公開し続けてきました。`}</p>
    <p>{`この記事では企業のテックブログを一定のリズムで継続運営する方法を、メドレーの事例に沿って紹介します。テックブログを継続運営することにお困りの方の手助けになれば幸いです。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f42bbdc03fec0158a3b7300d64b7114f/b7244/top.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAACYklEQVQoz32TS0gVURzGp4S2PRBcSmBBEIRgiyB6LNq0qWUEbQJpo2APE4QiVOxBt9smXwkSRuCmF1FJiyjDhQaW1jWtiK7P6533zDkzd+7jF3OuXmrTgY9Znd//+3/zHS0nBOHKKoFhYJoWlmkRhSGFKCIXhhVFuRzC97EsC9u2yWaz6LqB67hKjuPi+z5aTgbkHIcYbFs2Wd3AsB1cXxDl8wq0AZRClIdaFoYyYCKlrCgIAjR1IYooFPJkszqpmRRT4xNMT04R2jZCSAXMrzt2XfcfhUFAPsqpgbE0tZKURIB8+Qaz/jDG7v24dQ2MNrYw9PQVpm7gC4GQEtfz0Q0Ly3bwPF+52tgg/paBQpIH7L4hfm/aQbq6jqUtNQxfusrgyDPsNR3petiGhW3a6Jksnu0gfKFy8zyv7LACXHcYDo8gttYS1u7D3NXAQKKHvuejSP5zSiWVZwyurBznEJ/Pb8e4e/ocA43nudPURn/fA3513cboTuIm7uEke/FGnmBfT+J3JXjXe5/3kx/xPU9BXccpAwNZ9jD46DHb9x5iz5GT1DQc49SJM8id9aQ1jUWtiuXN1WQOHmepqpo1bRutB45yZ2AQCgUFK9fmL4dj4xNcaOvkWkeCi1du0N87hOy4hdN0GaelHae9EyfZg3O2GaO5lYfdN/nw4jXFUlF1NK5VOcP1QOPeWUJgxVUpFtUQMwzJeEJlXIpzpkTW9TCEJMxFlEolZSiGxT9Hq7yEKCI0TKxPX9Cnv+LOpAgWFkmnF5ib+056YZml5QyrKxm+zf8kNTvP7NwP9Vrijm708A9fMQQD+BBEZwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "メドレーのテックブログのトップページ",
            "title": "メドレーのテックブログのトップページ",
            "src": "/static/f42bbdc03fec0158a3b7300d64b7114f/c1b63/top.png",
            "srcSet": ["/static/f42bbdc03fec0158a3b7300d64b7114f/5a46d/top.png 300w", "/static/f42bbdc03fec0158a3b7300d64b7114f/0a47e/top.png 600w", "/static/f42bbdc03fec0158a3b7300d64b7114f/c1b63/top.png 1200w", "/static/f42bbdc03fec0158a3b7300d64b7114f/d61c2/top.png 1800w", "/static/f42bbdc03fec0158a3b7300d64b7114f/97a96/top.png 2400w", "/static/f42bbdc03fec0158a3b7300d64b7114f/b7244/top.png 3348w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`メドレーのテックブログのトップページ`}</em></p>
    <h1>{`テックブログ運営にまつわる課題`}</h1>
    <p>{`テックブログ運営で最も難しいことは、継続的に記事を執筆し公開し続けることだと思います。`}</p>
    <p>{`過去を振り返ると、今回紹介する方法をとる前も、コンスタントに記事を公開していました。しかし、記事を安定供給できる体制ではありませんでした。`}</p>
    <p>{`具体的には、社内勉強会で発表した内容をテックブログにも書いてもらう運用をしていた時期があり、勉強会の準備とテックブログの執筆の両方を業務と並行して行うことは、執筆者にとって相当な負荷となっていました。執筆者の業務が忙しくなり、公開が滞るときもありました。`}</p>
    <p>{`もちろん有志で執筆してもらうケースも多々ありましたが、このような負荷の高いルールに頼る運営は苦しく、長く続きませんでした。`}</p>
    <p>{`それでもメドレーでは、採用活動の一環として社外のエンジニアやデザイナー、プロダクト開発に関わる方々にメドレーのことを知っていただきたいという目的があり、どうにかテックブログを継続できる方法を模索していました。`}</p>
    <h1>{`テックブログを継続運営するための取り組み`}</h1>
    <p>{`ここからは、上記の課題を改善し、メドレーで約 2 年半継続してきたテックブログ運営の方法を紹介します。`}</p>
    <p>{`メドレーでは、テックブログ運営委員会を設立し、組織的に運営するというアプローチをとっています。`}</p>
    <p>{`具体的にどのように運営しているのか、以下の順に紹介していきます。`}</p>
    <ul>
      <li parentName="ul">{`運営委員会のメンバー構成`}</li>
      <li parentName="ul">{`運営委員会の活動`}</li>
      <li parentName="ul">{`執筆推進の流れ`}</li>
      <li parentName="ul">{`運営委員会の KPI`}</li>
      <li parentName="ul">{`大切にしていること`}</li>
    </ul>
    <h1>{`運営委員会のメンバー構成`}</h1>
    <p>{`まずはメンバー構成についてです。`}</p>
    <h2>{`リーダー（必須）`}</h2>
    <ul>
      <li parentName="ul">{`テックブログや運営体制のビジョンを示すのが主な役目`}</li>
      <li parentName="ul"><strong parentName="li">{`会社のブランディング戦略や採用強化中の職種・ポジションを理解し、公開する記事の方向性や品質をコントロールする必要があるため、`}</strong>{`会社の意思決定者（ CTO やマネージャー陣、また採用チームなど）と密に連携を取れる人が良い`}</li>
    </ul>
    <h2>{`コーディネーター（必須）`}</h2>
    <ul>
      <li parentName="ul">{`記事執筆推進や定例のファシリテーション、議事録の共有が主な役目`}</li>
      <li parentName="ul"><strong parentName="li">{`記事ネタの収集や執筆依頼を円滑に進行するため、各事業からコーディネーターを選出することがポイント`}</strong></li>
      <li parentName="ul">{`メドレーでは人材プラットフォーム事業と医療プラットフォーム事業の 2 事業から、それぞれ 1 名ずつエンジニアを選出している`}</li>
    </ul>
    <h2>{`アドバイザー（任意）`}</h2>
    <ul>
      <li parentName="ul">{`記事ネタの提案や運営体制の改善などが主な役目`}</li>
      <li parentName="ul"><strong parentName="li">{`採用活動に対する解像度が高い人や会社全体を広く見ている人に協力してもらうと記事ネタの収集力が高まるため、適切に巻き込んでいくと良い`}</strong></li>
      <li parentName="ul">{`メドレーでは採用チームの人や CTO 室所属の人にアドバイザーとして参加してもらっている`}</li>
    </ul>
    <p>{`現在メドレーではリーダー 1 名、コーディネーター 2 名、アドバイザー 3 名の計 6 名で運営委員会を構成しています。各メンバーは運営委員会とは別にメインの業務を持ちながら、兼任で活動しています。`}</p>
    <p>{`この体制により、組織全体を縦と横に幅広くカバーでき、テックブログの運営を効率的に進めることができています。`}</p>
    <p>{`特にコーディネーターについては、各事業から最低 1 名を選出することで、それぞれの事業内で行われた施策や活動を把握しやすくなり、組織規模の拡大にも対応しやすくなります。`}<strong parentName="p">{`継続的な運営を実現するためには、業務の負担が特定の事業に偏らないような体制を整えることが大切です。`}</strong></p>
    <p>{`チームで協力して活動することで、個人に依存しない仕組みも築くことができ、突発的な問題や課題が発生しても、メンバー全員で協力して解決できます。`}</p>
    <h1>{`運営委員会の活動`}</h1>
    <p>{`続いて、運営委員会の中でやってることについてです。定例、振り返り、執筆推進が主になります。`}</p>
    <h2>{`定例`}</h2>
    <ul>
      <li parentName="ul">{`隔週で開催`}</li>
      <li parentName="ul">{`アジェンダは前回決めたネクストアクションの状況共有、執筆状況の共有、次月以降の記事案の検討、その他議論共有`}</li>
      <li parentName="ul"><strong parentName="li">{`ここでのポイントとして、スケジュールを逆算して次の記事案を必ず決めることが超重要（決まらないまま終わらない）`}</strong></li>
      <li parentName="ul">{`例えば、記事を来月の最終営業日に公開する場合、遅くとも今月の中旬には記事案が確定し執筆者への合意もとれてる状態になってる必要があるので、スケジュールを逆算して次の記事案を必ず決める`}</li>
    </ul>
    <h2>{`振り返り`}</h2>
    <ul>
      <li parentName="ul">{`年 1 回で実施`}</li>
      <li parentName="ul">{`今年度を振り返り、次年度の継続アクション・改善アクションを決める`}</li>
      <li parentName="ul">{`直近の振り返りでは、技術系記事の数を増やしたことによる PV 数の増加や、カジュアル面談の場でテックブログの話題になることで面談がしやすくなったという成果も確認でき、継続アクションのひとつとすることを決定できた`}</li>
    </ul>
    <h2>{`執筆推進`}</h2>
    <ul>
      <li parentName="ul">{`以下で詳細に説明`}</li>
    </ul>
    <h1>{`執筆推進の流れ`}</h1>
    <p>{`続いて、執筆推進の流れを詳しく紹介します。`}</p>
    <p>{`全体の流れは図の通りで、Step 1 から Step 3 までの流れで記事を作成していきます。`}</p>
    <p>{`時間軸としては、Step 1 で記事の執筆依頼を終えた後、Step 2 を 3 ~ 4 週間くらいで、Step 3 を 2 週間くらいで完了させ、記事を公開するという感じで進めています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/365f37a78f40fd0e416cb5b0d1cb6357/cf8e5/flow.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB+UlEQVQ4y62TSSuGURTHL8oUJZR5LGSIkFJkLERSNqKUlEKxQKYiygqRbPkGykz5CEKmjZUFK1/gzfD66f+c3l4LS7du55zfGZ6nc+5xnJ/D8zN8fMDnJ96RfH8Hny/Ivr7sivn9QSa/mHTAUVEB3d0wPAzt7TA2Bh0d0NsLIyPQ1QXj49DWZlyssTHIOjthdBTq6mBlBUdsLFRVGYiKgpQUiIiA4mKoqQHnID3dfAUFMDBgLDkZoqMhKQn6+42FhuKYmYGlJZibs7u7C9PTMD9v9sSEsdlZWFwMxu7sGFPc8jJMTcHFBY5/Po7VVdjbg4cHuL+HlxeTNzdweQl3d/D6avL21pikmHIeH81Wjs+Ho6wMysuhpAQyM6GlBXJzob4eBgfNV1sLhYXQ0ABDQ1BdDU1NkJYG8fFQWmo9bm7GERNjhRQUFmYDSkyE/Hzo6YHISLyXoObn5EBrqyVXVlqc/IrVUIqKcJycwOkpHBzA8TFcXcHREezvWyukix0eWoyY9OtrvDd8dmYxqvP2hvMe5b8Opa8PJidha8uuvrS5CWtrJgNse9v0jQ1YX4enJ6ugDQtskd+P8x6wmpqQYH3QcGTn5VnfQkLMjoszf6Bf6mVg/b6/f/1haipkZdmGKEnrpY3IyMDzaYpiGpz82dkQHg4LC38W/AFPYSRS6m4J+QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "記事執筆の流れ",
            "title": "記事執筆の流れ",
            "src": "/static/365f37a78f40fd0e416cb5b0d1cb6357/c1b63/flow.png",
            "srcSet": ["/static/365f37a78f40fd0e416cb5b0d1cb6357/5a46d/flow.png 300w", "/static/365f37a78f40fd0e416cb5b0d1cb6357/0a47e/flow.png 600w", "/static/365f37a78f40fd0e416cb5b0d1cb6357/c1b63/flow.png 1200w", "/static/365f37a78f40fd0e416cb5b0d1cb6357/cf8e5/flow.png 1402w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`記事執筆の流れ`}</em></p>
    <p>{`では、Step 1 から順に説明していきます。`}</p>
    <h2>{`Step 1`}</h2>
    <h3>{`1. 記事案の選定と決定`}</h3>
    <ul>
      <li parentName="ul">{`担当: 運営委員会`}</li>
      <li parentName="ul">{`定例で記事案を決定する`}</li>
      <li parentName="ul">{`後述する頭出しのときに NG が出た場合に備えて候補をいくつか用意しておく`}</li>
      <li parentName="ul">{`記事案のカテゴリ`}
        <ul parentName="li">
          <li parentName="ul">{`技術系記事（メドレーならではの制約や困ったポイントなどがあれば、それらを明確にする）`}
            <ul parentName="li">
              <li parentName="ul">{`例: `}<a parentName="li" {...{
                  "href": "https://developer.medley.jp/entry/2024/12/03/100122"
                }}>{`トイルの削減も、情報漏洩リスクの削減も、両方手に入れる。IAM Identity Center は欲張りなんだ。`}</a></li>
            </ul>
          </li>
          <li parentName="ul">{`インタビュー記事`}
            <ul parentName="li">
              <li parentName="ul">{`例: `}<a parentName="li" {...{
                  "href": "https://developer.medley.jp/entry/2024/04/05/234106"
                }}>{`FY22 新卒入社エンジニアはこの 1 年でどのような成長をしてきたのかインタビューしました`}</a></li>
            </ul>
          </li>
          <li parentName="ul">{`イベント参加記事`}
            <ul parentName="li">
              <li parentName="ul">{`例: `}<a parentName="li" {...{
                  "href": "https://developer.medley.jp/entry/2025/01/09/115753"
                }}>{`pmconf 2024 にゴールドスポンサーとして協賛しました！`}</a></li>
            </ul>
          </li>
          <li parentName="ul">{`etc`}</li>
        </ul>
      </li>
    </ul>
    <h3>{`2. 記事案の頭出し`}</h3>
    <ul>
      <li parentName="ul">{`担当: コーディネーター`}</li>
      <li parentName="ul"><strong parentName="li">{`執筆完了後に NG になるのを防ぐため、先に CTO へ頭出しを行う`}</strong></li>
      <li parentName="ul">{`定例の議事録共有と合わせる形で、来月はこの記事で進める予定であることを共有する`}</li>
    </ul>
    <h3>{`3. 執筆の事前依頼`}</h3>
    <ul>
      <li parentName="ul">{`担当: コーディネーター`}</li>
      <li parentName="ul">{`突然執筆を依頼して驚かれないように、執筆を正式依頼する前に執筆者に依頼の頭出しをしておく`}</li>
    </ul>
    <h3>{`4. 執筆の正式依頼`}</h3>
    <ul>
      <li parentName="ul">{`担当: コーディネーター`}</li>
      <li parentName="ul"><strong parentName="li">{`執筆は業務と同じ扱いなので、Slack のパブリックなチャンネルで、メンションに CTO や執筆者の上長など関係者を含み、執筆を正式に依頼する`}</strong></li>
    </ul>
    <h2>{`Step 2`}</h2>
    <h3>{`5. スケジュールの作成`}</h3>
    <ul>
      <li parentName="ul">{`担当: コーディネーター`}</li>
      <li parentName="ul">{`執筆、初稿提出、レビュー、最終レビューまでの流れをカレンダーに落とし込み、関係者に共有する`}</li>
      <li parentName="ul"><strong parentName="li">{`締切を明確にしつつ、通常業務の合間でも最後まで執筆作業を走りきれるよう、しっかりコーディネーターが進捗確認などしつつフォローする`}</strong></li>
    </ul>
    <h3>{`6. 執筆環境の構築`}</h3>
    <ul>
      <li parentName="ul">{`担当: コーディネーター`}</li>
      <li parentName="ul">{`GitHub で記事を管理しているので、記事のベースファイルを作成し、Issue と Pull Request を用意する`}</li>
      <li parentName="ul">{`執筆者の負担をなるべく減らし、記事執筆のみに集中してもらえるようにする`}</li>
    </ul>
    <h3>{`7. 記事構成案の作成`}</h3>
    <ul>
      <li parentName="ul">{`担当: 執筆者`}</li>
      <li parentName="ul"><strong parentName="li">{`さあ執筆しよう！という気持ちを抑え、わかりやすい記事構成となるよう、先に記事の構成案（章立てと各章の概要）を作り骨子を固める（コーディネーターが壁打ちなどサポート）`}</strong></li>
    </ul>
    <h3>{`8. 執筆`}</h3>
    <ul>
      <li parentName="ul">{`担当: 執筆者`}</li>
      <li parentName="ul">{`作成した記事構成案をベースにして執筆する`}</li>
    </ul>
    <h2>{`Step 3`}</h2>
    <h3>{`9. 運営レビュー`}</h3>
    <ul>
      <li parentName="ul">{`担当: 運営委員会、その他関係者（執筆者の上長など）`}</li>
      <li parentName="ul">{`誤字脱字の基本的な確認に加え、記事をより良くするための提案を行う`}</li>
      <li parentName="ul">{`後続の最終レビューの負担を減らすために、ここでできる限り記事を公開可能な状態まで持っていく`}</li>
    </ul>
    <h3>{`10. 最終レビュー`}</h3>
    <ul>
      <li parentName="ul">{`担当: 広報チーム、CTO`}</li>
      <li parentName="ul">{`広報チームには広報視点で問題ないかを確認してもらい、CTO には網羅的な視点で問題ないかを最終確認してもらう`}</li>
      <li parentName="ul">{`ここで記事を公開可能な状態に仕上げる`}</li>
    </ul>
    <h3>{`11. 公開`}</h3>
    <ul>
      <li parentName="ul">{`担当: コーディネーター、執筆者、広報チーム`}</li>
      <li parentName="ul">{`記事を公開し、社内外に共有する`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://x.com/medley_dev"
            }}>{`メドレーの公式 X アカウント`}</a>{`でも発信しているので、フォローよろしくお願いします`}</li>
        </ul>
      </li>
    </ul>
    <h1>{`運営委員会の KPI`}</h1>
    <p>{`続いて、運営委員会で設定している KPI についてです。`}</p>
    <ul>
      <li parentName="ul">{`KPI はシンプルに、ひと月に最低 1 記事以上を公開すること`}</li>
      <li parentName="ul"><strong parentName="li">{`まずは継続することが大事なので、最初はシンプルな KPI にしておくこと。もし KPI を増やすなら継続できる体制が整ってから`}</strong></li>
    </ul>
    <h1>{`大切にしていること`}</h1>
    <p>{`最後に、ここまでで太字で強調したポイント以外に、運営上特に大切な 2 点を紹介します。`}</p>
    <h2>{`テックブログをやる目的を明確にする`}</h2>
    <ul>
      <li parentName="ul">{`何をやるにしても目的を定めて共通認識として持つことは大切ですが、テックブログの運営も同様に目的を明確にしてブレないようにする`}</li>
      <li parentName="ul"><strong parentName="li">{`メドレーでは採用に繋げることを目的としており、テックブログを通じて社外の方々にメドレーことを知っていただき、解像度を上げてもらえるように努めている`}</strong></li>
      <li parentName="ul">{`目的が明確だと記事案の検討にも軸ができ、例えばプロダクトマネージャーの採用を強化してるならプロダクトマネージャー向けの記事を用意したりという動きができる`}</li>
    </ul>
    <h2>{`執筆は業務であることを明確にする`}</h2>
    <ul>
      <li parentName="ul">{`記事執筆において軽視されがちな印象で、執筆が業務であることが当たり前のようで当たり前になってなかったりする`}</li>
      <li parentName="ul">{`通常業務との兼ね合いは必要になるが、業務であるということはイコール業務中に時間を確保して取り組むべきことである`}</li>
      <li parentName="ul">{`しかし、そこがふわっとしていると業務中に執筆し辛くなり、執筆がビハインドして公開が遅れる可能性が高くなる`}</li>
      <li parentName="ul"><strong parentName="li">{`メドレーでは運営委員会の発足時に CTO から執筆は業務としてしっかり取り組んで欲しいという話があり、その文脈を含んで運営オペレーションを構築してきた背景がある`}</strong></li>
      <li parentName="ul">{`例えば執筆依頼を執筆者の上長を含めて行ったり、皆が見える場所で依頼などのコミュニケーションをとったり、公開までのスケジュールを細かく引いて開発プロジェクトと同じように進めたり`}</li>
    </ul>
    <h1>{`おわりに`}</h1>
    <p>{`メドレーが約 2 年半継続してきたテックブログ運営の方法を紹介しました。`}</p>
    <p>{`リーダーとコーディネーターを中心とした運営委員会により、組織全体を縦と横に幅広くカバーし、開発プロジェクトと同じように関係者との調整やスケジューリングを行い、運営にも執筆者にも負担が集中しない無理のない体制を築くことにより、テックブログを継続運営しています。`}</p>
    <p>{`会社の規模やフェーズによってはそのまま取り入れるのは難しいかもしれません。エッセンスだけを抽出して会社のスタイルや風土に合う形にカスタマイズし、ミニマムに運営を始めて推進していくのも良いかもしれません。`}</p>
    <p>{`今回の記事が、テックブログを継続運営することにお困りの方の参考になれば幸いです。`}</p>
    <h1>{`We’re hiring`}</h1>
    <p>{`メドレーでは一緒に働く仲間を大募集しています。カジュアル面談も実施しておりますので、少しでもご興味のある方はぜひご応募お待ちしております。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      